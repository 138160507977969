import { cn } from '@cardo/lib';
import { Badge, Card } from '@cardo/ui';
import { TripReport } from '@cardo/types';
import UserCardLine from './UserCardLine';

export function getTripReportTypeDetails({
  tripReport,
}: {
  tripReport: TripReport | null;
}) {
  let backgroundImage: string = '';
  let badges: string[] = [];
  const attrs = tripReport?.attributes;
  switch (attrs?.type) {
    case 'hotel': {
      const hotel = attrs.hotel?.data?.attributes;
      backgroundImage = `url(${hotel?.featuredImage?.data?.attributes?.url ?? hotel?.coverImage?.data?.attributes?.url ?? ''})`;
      const location = hotel?.location?.data?.attributes;
      badges = [
        [location?.placeName, location?.region, location?.country]
          .filter(Boolean)
          .join(', '),
        hotel?.hotelGroup?.data?.attributes?.rewardsProgram ?? '',
      ];
      break;
    }
    case 'flight': {
      const flight = attrs.flightProduct?.data?.attributes;
      backgroundImage = `url(${flight?.coverImage?.data?.attributes.url ?? ''})`;
      badges = [
        flight?.fareClass?.data?.attributes?.name ?? '',
        flight?.airline?.data?.attributes?.name ?? '',
      ];
      break;
    }
    case 'airport lounge': {
      const lounge = attrs.airportLounge?.data?.attributes;
      backgroundImage = `url(${lounge?.featuredImage?.data?.attributes?.url ?? lounge?.coverImage?.data?.attributes?.url ?? ''})`;
      badges = [
        lounge?.airport?.data?.attributes?.name ?? '',
        lounge?.name ?? '',
      ];
      break;
    }
  }

  return { backgroundImage, badges };
}

export default function TripReportCard({
  className,
  tripReport,
}: {
  className?: string;
  tripReport: TripReport | null;
}) {
  const { backgroundImage, badges } = getTripReportTypeDetails({ tripReport });
  return (
    <Card
      className={cn('p-0 flex flex-col border-0 shadow-lg h-full', className)}
    >
      <div
        className="w-full h-[240px] md:h-[270px] bg-cover bg-center rounded-xl flex items-start justify-end p-4"
        style={{ backgroundImage }}
      />

      <div className="flex flex-col gap-2 md:gap-4 p-4 flex-grow">
        <div className="hidden md:flex flex-wrap gap-2 ">
          {badges.map(
            (badge, i) =>
              badge && (
                <Badge
                  key={badge}
                  color={i ? 'yellow' : 'blue'}
                  className="px-3 py-1 rounded-2xl text-md font-semibold max-w-full"
                >
                  <p className="truncate">{badge}</p>
                </Badge>
              ),
          )}
        </div>
        <h2 className="text-lg md:text-xl font-semibold">
          {tripReport?.attributes.title ?? ''}
        </h2>
        <p className="text-sm md:text-base line-clamp-2 md:line-clamp-4">
          {tripReport?.attributes.tagline ?? ''}
        </p>
        {tripReport?.attributes?.influencer.data && (
          <UserCardLine
            className="items-center mt-auto "
            influencer={tripReport?.attributes?.influencer?.data}
          />
        )}
      </div>
    </Card>
  );
}
