import { cn, getInfluencerName } from '@cardo/lib';
import { UserAvatar } from '@cardo/ui';
import { Influencer } from '@cardo/types';
import { MdVerified } from 'react-icons/md';

export default function UserCardLine({
  className,
  influencer,
}: {
  className?: string;
  influencer: Influencer;
}) {
  return (
    <div className={cn('flex gap-2', className)}>
      {influencer?.attributes?.avatar && (
        <UserAvatar
          avatar={influencer?.attributes?.avatar ?? null}
          className="h-[40px] w-[40px] md:h-[50px] md:w-[50px]"
        />
      )}
      <div className="flex items-center gap-1.5">
        <span className="text-lg md:text-xl font-bold">
          {getInfluencerName(influencer) ?? ''}
        </span>
        {influencer?.attributes.verified && (
          <div className="relative inline-flex items-center justify-center">
            <div className="absolute w-4 h-4 bg-white rounded-full" />
            <MdVerified className="relative text-blue-500 h-6 w-6 flex-shrink-0" />
          </div>
        )}
      </div>
    </div>
  );
}
