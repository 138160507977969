import * as React from 'react';

import { cn } from '@cardo/lib';

interface CardProps {
  children: React.ReactNode;
  className?: string;
}

export function Card({ children, className }: CardProps) {
  return (
    <div
      className={cn(
        'border shadow rounded-xl px-8 py-4 flex flex-col',
        className
      )}
    >
      {children}
    </div>
  );
}
